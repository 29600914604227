import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Insta from '../features/Insta';
import Twitter from '../features/Twitter';

const Snsicon = () => {

    const Container = css`
        display: flex;
        justify-content: center;
        align-items: center;
    `
  return (
    <li css = {Container}>
        <Insta />
        <Twitter />
    </li>
    
  )
}

export default Snsicon