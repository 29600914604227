import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
/** @jsxImportSource @emotion/react */
import { Global, css } from "@emotion/react";

const App = () => {
    const global = css`
    @import url('https://fonts.googleapis.com/css2?family=Aldrich&display=swap');
    body {
      background: #212024;
    }
        `;
  return (
    <div className="App">
        <Global styles={global}/>
        <Routes>
            <Route path="/" element={ <Home /> } />
        </Routes>

    </div>
  )
}

export default App