import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const Copylight = () => {

    const Center = css`
        text-align: center;
        color: #F5F5F5;
        padding-bottom: 20px;
    `
  return (
    <div css = {Center}>© mov研</div>
  )
}

export default Copylight