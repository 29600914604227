/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { css } from "@emotion/react";

const Textblink = () => {
  const [isAnimationComplete, setAnimationComplete] = useState(false);


  useEffect(() => {
    const TxtRotate = function (el, toRotate, period) {
      this.toRotate = toRotate;
      this.el = el;
      this.loopNum = 0;
      this.period = parseInt(period, 10) || 2000;
      this.txt = "";
      this.isDeleting = false;
      this.tick();
    };

    TxtRotate.prototype.tick = function () {
      const i = this.loopNum % this.toRotate.length;
      const fullTxt = this.toRotate[i];

      if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
      } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
      }

      this.el.innerHTML = `<span class="wrap">${this.txt}</span>`;

      let delta = 200;

      if (this.isDeleting) delta /= 2;

      if (!this.isDeleting && this.txt === fullTxt) {
        setAnimationComplete(true);
        return;
      } else if (this.isDeleting && this.txt === "") {
        this.isDeleting = false;
        this.loopNum++;
        delta = 200;
      }

      setTimeout(() => this.tick(), delta);
    };

    const elements = document.getElementsByClassName("txt-rotate");
    for (let i = 0; i < elements.length; i++) {
      const toRotate = elements[i].getAttribute("data-rotate");
      const period = elements[i].getAttribute("data-period");
      if (toRotate) {
        new TxtRotate(elements[i], JSON.parse(toRotate), period);
      }
    }
  }, []);

  return (
    <div css={containerStyle}>
      <link
        href="https://fonts.googleapis.com/css?family=Raleway:200,100,400"
        rel="stylesheet"
        type="textblink/css"
      />
      <h1>
        {isAnimationComplete ? (
          <>COMING SOON..<span css={dotStyle}>.</span></>
        ) : (
          <span
            className="txt-rotate"
            data-period="2000"
            data-rotate='[ "COMING SOON..." ]'
          ></span>
        )}
      </h1>
      <style>
        {`
          .txt-rotate > .wrap {
            border-right: 0.08em solid #666;
          }
        `}
      </style>
    </div>
  );
};

const containerStyle = css`
  display: flex;
  justify-content: center;
  font-size: 7px;

  h1 {
    font-family: "Aldrich", serif;
    font-weight: 800;
    font-style: normal;
    font-size: 30px;
    color: #F5F5F5;
    padding: 20px 0;
  }
`;

const dotStyle = css`
  animation: blink-animation 1s steps(2, start) infinite;

  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
`;

export default Textblink;
