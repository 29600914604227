/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import Hayato from '../asset/img/Hayato.png';
import Makigon from '../asset/img/Makigon.png';
import Koto from '../asset/img/Koto.png';
import Sakura from '../asset/img/Sakura.png';
import Shun from '../asset/img/Shun.png';
import Syouta from '../asset/img/Syouta.png';
import Kaito from '../asset/img/Kaito.png';
import Sakaken from '../asset/img/Sakaken.png';

const containerStyle = css`
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
`;

const imageStyle = (isVisible) => css`
  width: 200px;
  opacity: ${isVisible ? 1 : 0};
  transition: opacity 0.5s ease-in;
  margin: 40px;
  box-shadow: 5px 3px 3px #898989;
`;

const images = [
  Syouta,Koto,Sakaken,Makigon,Kaito,Hayato,Sakura,Shun
];

const SequentialImageFadeIn = () => {
  const [visibleImages, setVisibleImages] = useState(Array(8).fill(false));

  useEffect(() => {
    visibleImages.forEach((_, index) => {
      setTimeout(() => {
        setVisibleImages(prev => {
          const newVisibility = [...prev];
          newVisibility[index] = true;
          return newVisibility;
        });
      }, index * 300);
    });
  }, []);

  return (
    <div css={containerStyle}>
      {images.map((src, index) => (
        <img
          key={index}
          src={src}
          alt={`Image ${index + 1}`}
          css={imageStyle(visibleImages[index])}
        />
      ))}
    </div>
  );
};

export default SequentialImageFadeIn;