import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Pictures from '../components/Pictures';

const Card = () => {

    const Wrapper = css`
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding: 15px;
        margin: 0 auto;
        width: 90%;
    `

  return (
    <diV css = {Wrapper}>
        <Pictures />
    </diV>
  )
}

export default Card