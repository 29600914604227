import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Top from '../components/Top';
import Text from '../components/Textblink';
import Snsicon from '../components/Snsicon';
import Copylight from '../features/Copylight';
import Card from './Card';


const Home = () => {

  const Wrapper = css`
    display: flex;
    padding-top: 70px;
    align-items: center;
    flex-direction: column;
  `

  const Central = css`
    text-align: center;
    color: #F5F5F5;
  `

  return (
    <div css = {Wrapper}>
      <Top />
      <Text css = {Central} />
      <Snsicon />
      <Card />
      <Copylight />
    </div>
  )
}

export default Home