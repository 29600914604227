import React from 'react'
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import MOVIMG from "../asset/img/backimage.png";

const Top = () => {

  const Container = css`
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: 'url(@/src/img/backimage)';
  ` 

  const Img = css`
    width: 50%;
  `
  
  const TopFont = css`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
  `

  return (
    <div css = {Container}>
      <img src={MOVIMG} css= {Img}/>
      
      {/* <p css = {TopFont}>Mov研</p> */}
    </div>
  )
}

export default Top